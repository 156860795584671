<template>
  <ElementFormItem
    :prop="inputKey"
    :rules="rules"
    :label="name"
    :error="customError"
    class="form-generator__content-item"
  >
    <StitchTooltip
      :message="disabledMessage"
      :is-disabled="!isDisabled"
    >
      <ElementInputNumber
        v-model="inputData"
        class="form-generator__text-input"
        :placeholder="name"
        :disabled="isDisabled"
        :min="MIN_VALUE"
        :max="MAX_VALUE"
        :precision="PRECISION"
        :step="STEP"
        controls-position="right"
        @input="setData"
      />
    </StitchTooltip>
  </ElementFormItem>
</template>

<script>
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'

const MIN_VALUE = 0
const MAX_VALUE = 99999999999999
const PRECISION = 2
const STEP = 0.1

export default {
  name: 'InputNumber',

  mixins: [InputMixin],

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: Number,
      required: false,
      default: null
    }
  },

  data () {
    return {
      MAX_VALUE,
      MIN_VALUE,
      PRECISION,
      STEP
    }
  },

  methods: {
    /**
     */
    initInput () {
      if (this.defaultValue === null) {
        this.inputData = null
      } else {
        this.inputData = this.defaultValue || ''
      }

      this.setData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/utils';

.form-generator__text-input {
  min-width: $input-width;
}
</style>
