/* eslint-disable camelcase -- Not all variables need to be in camel case in this situation. Some are supposed to be in snake case instead */

import { formatToLocaleDateShort } from '@/services/utils'

/**
 * Assigns a property from the user object to the data object, optionally transforming the value.
 * Converts the property key from snake_case to camelCase.
 *
 * @param {object}            user              - The user object containing the property to be assigned.
 * @param {object}            data              - The data object to which the property will be assigned.
 * @param {string}            propertyKey       - The name of the property to be assigned, in snake_case.
 * @param {*}                 defaultValue      - The default value to use if the property is null.
 * @param {(Function | null)} transformFunction - A function to transform the property value, or null to skip transformation.
 */
function assignProperty (
  user,
  data,
  propertyKey,
  defaultValue,
  transformFunction = null
) {
  // Convert propertyKey from snake_case to camelCase
  const camelCaseKey = propertyKey
    .replace(/_(\w)/g, match => match.toUpperCase(1))
    .replace(/_(\w)/g, (_, g) => g.toUpperCase())

  // Check if the property is defined
  if (Object.prototype.hasOwnProperty.call(user, propertyKey)) {
    // Check if the property is null
    if (user[propertyKey] === null) {
      // Yes? Then use the default value
      data[camelCaseKey] = defaultValue
    } else {
      // Not empty? Then apply transformation function if provided, otherwise directly assign from the 'user' object
      data[camelCaseKey] = transformFunction
        ? transformFunction(user[propertyKey])
        : user[propertyKey]
    }
  }
  // If not, then don't assign it
}

export default {
  from: {
    /**
     * @param   {object} user
     * @returns {object}
     */
    user (user) {
      // Define the common attributes that are retrieved from both BE APIs:
      // /api/manage/users and /api/manage/teams/<team-id>/users
      const {
        id,
        email,
        username,
        is_active,
        can_manage_users,
        is_staff_member,
        groups
      } = user

      const data = {
        id,
        email: email,
        username: username,
        isActive: is_active,
        isStaff: is_staff_member,
        canManageUsers: can_manage_users,
        teams: groups
      }

      // Assign the bellow attributes only if they exists
      assignProperty(user, data, 'is_hub_admin', '-')
      assignProperty(user, data, 'is_vendor_admin', '-')
      assignProperty(user, data, 'date_joined', '-', date =>
        formatToLocaleDateShort(new Date(date))
      )
      assignProperty(user, data, 'last_login', '-', date =>
        formatToLocaleDateShort(new Date(date))
      )
      assignProperty(user, data, 'teams', [])
      assignProperty(user, data, 'role', {
        id: -1,
        name: '-',
        display_name: '-'
      })

      // Return
      return data
    }
  }
}
