<template>
  <ElementCard
    :body-style="{ padding: '0px' }"
    :class="[
      'library-card-item',
      {
        'library-card-item--disabled': isUploadingOrFailed
      }
    ]"
    :shadow="isHovered ? 'always' : 'never'"
    :title="processedItem.name"
  >
    <div
      class="library-card-item-preview"
      @mouseleave="handleCardHover(false)"
      @mouseover="handleCardHover(true)"
      @contextmenu.prevent.stop="handleCardContextMenu"
      @click.stop="handleCardClick"
    >
      <!-- ------------------------- -->
      <!-- Images -->
      <!-- ------------------------- -->
      <div class="library-card-item__image-group">
        <div
          :class="[
            'library-card-item__status-overlay',
            {
              'status--upload-pending':
                processedItem.status === UPLOAD_STATUS.PENDING
            },
            {
              'status--upload-failed':
                processedItem.status === JOB_STATUS.FAILED ||
                processedItem.status === UPLOAD_STATUS.FAILED
            },
            {
              'status--job-prepare-download':
                processedItem.status === JOB_STATUS.DOWNLOAD
            },
            {
              'status--job-started':
                processedItem.status === JOB_STATUS.STARTED ||
                processedItem.status === JOB_STATUS.PENDING
            },
            {
              'status--job-created': processedItem.status === JOB_STATUS.CREATED
            }
          ]"
        >
          <div
            v-if="isRunningJob"
            class="library-card-item__status-overlay-icon"
          >
            <StitchLoader />
          </div>
        </div>

        <div
          v-if="processedItem.libraryType === LIBRARY_TYPE.JOB"
          class="library-card-item__image-multiple"
        >
          <div
            v-for="(url, thumbnailIndex) in multipleThumbnailUrl"
            :key="thumbnailIndex"
            :style="{ backgroundImage: `url('${url}')` }"
            class="image"
          />
        </div>
        <div
          v-else-if="thumbnailUrl"
          :class="[
            'library-card-item__image image',
            {
              'library-card-item__image--fabrics':
                processedItem.libraryType === LIBRARY_TYPE.FABRIC
            }
          ]"
          :style="{
            backgroundImage: `url('${thumbnailUrl}')`
          }"
        />
        <div
          v-else
          :class="[
            'library-card-item__image image placeholder',
            {
              'placeholder--preparing':
                shouldShowPreparingThumbnail(processedItem)
            }
          ]"
        >
          <div
            v-if="shouldShowPreparingThumbnail(processedItem)"
            class="preparing-thumbnail"
          >
            <StitchLoader />
            Preparing Thumbnail
          </div>
        </div>
      </div>
      <!-- ------------------------- -->
      <!-- Infos -->
      <!-- ------------------------- -->
      <div class="library-card-item__info">
        <div class="library-card-item__info-title">
          {{ processedItem.name }}
        </div>
        <div class="library-card-item__info-sub-title">
          <div
            v-if="
              processedItem.libraryType === LIBRARY_TYPE.FABRIC &&
                sustainabilityRatingValue
            "
            class="library-card-item__rate"
          >
            <span
              v-for="counter in sustainabilityRatingValue"
              :key="counter"
              class="library-card-item__rate-icon"
            />
          </div>
          <div class="library-card-item__message">
            <span>{{ itemMessage }}</span>
          </div>
          <span v-if="processedItem.libraryType === LIBRARY_TYPE.JOB">
            {{ itemCreatedAt }}
          </span>
        </div>
        <div
          v-if="
            processedItem.libraryType === LIBRARY_TYPE.FABRIC &&
              processedItem.weight
          "
          class="library-card-item__weight"
        >
          {{ processedItem.weight }} g/sqm
        </div>
        <div
          v-if="processedItem.libraryType === LIBRARY_TYPE.STYLE"
          class="library-card-item__info-sub-title"
        >
          <template v-if="isLoadingStatus">
            <StitchLoader
              size="mini"
              placement="start"
            />
          </template>
          <template v-else>
            <LibraryStatusSelector
              size="small"
              :item="processedItem"
              :transitions="directStatusTransitions"
              :reset="showStyleStatusActionForm"
              @update-status="updateStatus"
            />
          </template>

          <!-- Style Status Action form -->
          <FormGenerator
            :show-form-generator="showStyleStatusActionForm"
            :form-configuration="styleStatusActionFormConfiguration"
            :additional-data="processedItem"
            :original-data="actionConfirmationFormData"
            :default-data="styleStatusActionDefaultFormData"
            :options="styleStatusActionReasons"
            :is-creation="true"
            @form-close="onStyleStatusActionFormClose"
            @form-complete="completeStyleStatusActionForm"
          />
          <div>
            <span
              v-if="processedItem.qualityCheck"
              class="library-card-item__quality-check-icon"
            >
              <i class="el-icon-success" />
            </span>
            <span
              v-if="processedItem.unreadNotificationsCount > 0"
              class="library-card-item__unread-notifications-icon"
            >
              <ElementBadge
                :value="processedItem.unreadNotificationsCount"
                :hidden="processedItem.unreadNotificationsCount === 0"
                class="library-card-item__unread-notifications-badge"
              >
                <i class="el-icon-chat-dot-round" />
              </ElementBadge>
            </span>
          </div>
        </div>
      </div>
      <!-- ------------------------- -->
      <!-- Actions related to the library-card -->
      <!-- ------------------------- -->
      <div class="library-card-item__actions">
        <a
          v-if="
            !isWorkspaceLibraryItem &&
              hasModel3D(processedItem, 1, 2) &&
              isHovered
          "
          ref="downloadFile"
          data-testid="downloadTrigger"
          @click.stop="downloadFile({ item: processedItem })"
        >
          <i
            v-if="showDownloadSpinnerStatus !== processedItem.id"
            class="el-icon-download"
          />
          <StitchLoader
            v-else
            size="mini"
          />
        </a>
        <ElementDropdown
          v-else-if="
            !isWorkspaceLibraryItem && hasModel3D(processedItem) && isHovered
          "
          @command="downloadFile"
          @visible-change="handleFileDropdownVisibleChange"
        >
          <a
            data-testid="downloadTrigger"
            role="button"
            @click.stop="downloadFile({ item: processedItem })"
          >
            <i class="el-icon-download" />
          </a>
          <ElementDropdownMenu slot="dropdown">
            <ElementDropdownItem
              v-for="(value, index) in processedItem.filesDict.model3D"
              :key="index"
              :command="{ item: processedItem, fileIndex: index }"
              :data-testid="`downloadItem${index}`"
            >
              {{ value.description }}
            </ElementDropdownItem>
          </ElementDropdownMenu>
        </ElementDropdown>

        <template v-if="processedItem.libraryType === LIBRARY_TYPE.JOB">
          <span
            v-if="isRunningJob"
            class="el-dropdown-link"
            @click.stop="openConfirmDelete(processedItem)"
          >
            <i
              class="el-icon-close library-card-item__actions-delete library-card-item__actions-delete--icon"
            />
          </span>
        </template>

        <StitchDropdown
          v-if="showCardMenu"
          :append-to-body="true"
          :dropdown-items="getDropdownItems(processedItem)"
          :icon-type="ICON_TYPE.MENU"
          :placement="DROPDOWN_PLACEMENT.BOTTOM_END"
          :show-button="false"
          :show-dropdown="showDropdown"
          @visibility-change="handleVisibilityChange"
        />
      </div>
    </div>
  </ElementCard>
</template>

<script>
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { IMAGE_RESOLUTION } from '@/constants/image'
import {
  JOB_STATUS,
  PREPARE_THUMBNAIL_TIMEOUT,
  UPLOAD_STATUS
} from '@/constants/loadingStatus'
import {
  DROPDOWN_PLACEMENT,
  ICON_TYPE,
  STYLE_TYPE
} from '@/constants/dropdownOptions'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'

import { DataUtils } from '@/mixins/utils.js'
import { BrowzwearPlugin } from '@/mixins/browzwearPlugin'
import Utils, { formatToLocaleDateShort } from '@/services/utils'
import { ItemShape } from '@/types'
import LibraryStatusSelector from '@/components/library/LibraryStatusSelector.vue'
import { mapActions } from 'vuex'
import {
  ASSET_STATUS,
  REASONS_FOR_CANCELLATION,
  REASONS_FOR_RESUBMITTING
} from '@/constants/assetStatuses'
import { getFormConfig } from '@/services/formUtils'
import { FORM_NAME } from '@/constants/formName'

export default {
  name: 'LibraryCardItem',

  components: { LibraryStatusSelector },

  mixins: [DataUtils, BrowzwearPlugin],

  props: {
    processedItem: ItemShape.loose.isRequired
  },

  emits: ['delete-item'],

  data () {
    return {
      LIBRARY_TYPE,
      ICON_TYPE,
      STYLE_TYPE,
      UPLOAD_STATUS,
      JOB_STATUS,
      DROPDOWN_PLACEMENT,
      ASSET_STATUS,
      showDownloadSpinnerStatus: null,
      showDropdown: false,
      shouldDropdownPersist: false,
      isHovered: false,
      shouldDownloadButtonPersist: false,
      isLoadingStatus: false,
      showStyleStatusActionForm: false,
      styleStatusActionDefaultFormData: null,
      styleStatusActionReasons: {}
    }
  },

  computed: {
    /**
     * @returns {Array}
     */
    directStatusTransitions () {
      if (!this.processedItem) {
        return []
      }

      return this.processedItem.assetStatusTransitions
        .map(transition => {
          return {
            ...transition,
            action: ASSET_STATUS[transition.value.toUpperCase()].action
          }
        })
        .filter(
          transition => transition.label !== this.processedItem.assetStatus
        )
    },

    /**
     * @returns {object}
     */
    styleStatusActionFormConfiguration () {
      return getFormConfig(FORM_NAME.STATUS_CHANGE_CONFIRMATION)
    },

    /**
     * @returns {object}
     */
    actionConfirmationFormData () {
      return {
        name: this.processedItem.name,
        plmCode: this.processedItem.plmCode
      }
    },

    /**
     * @returns {string}
     */
    sustainabilityRatingValue () {
      return (
        this.processedItem.sustainabilityRating &&
        this.processedItem.sustainabilityRating.value
      )
    },

    /**
     * @returns {string}
     */
    thumbnailUrl () {
      return this.getThumbnail(this.processedItem, IMAGE_RESOLUTION.THUMBNAIL)
    },

    /**
     * @returns {string}
     */
    itemCreatedAt () {
      return formatToLocaleDateShort(this.processedItem.createdAt)
    },

    /**
     * @returns {Array}
     */
    multipleThumbnailUrl () {
      return this.getMultipleThumbnail(this.processedItem)
    },

    /**
     * @returns {boolean}
     */
    isRunningJob () {
      const runningJobStatuses = [
        JOB_STATUS.CREATED,
        JOB_STATUS.STARTED,
        JOB_STATUS.PENDING
      ]

      return runningJobStatuses.includes(this.processedItem.status)
    },

    /**
     * @returns {boolean}
     */
    showCardMenu () {
      const { libraryType } = this.processedItem

      return (
        (libraryType === LIBRARY_TYPE.JOB && !this.isRunningJob) ||
        this.isSingle3DFileItem
      )
    },

    /**
     * @returns {boolean}
     */
    isUploadingOrFailed () {
      return [UPLOAD_STATUS.PENDING, UPLOAD_STATUS.FAILED].includes(
        this.processedItem.status
      )
    },

    /**
     * @returns {string}
     */
    itemMessage () {
      if (this.hasModel3D(this.processedItem) || this.isWorkspaceLibraryItem) {
        return this.processedItem.subTitle
      } else {
        return this.processedItem.status || 'The 3D file is missing'
      }
    },

    /**
     * @returns {boolean}
     */
    isAssetLibraryItem () {
      return [
        LIBRARY_TYPE.BLOCK,
        LIBRARY_TYPE.FABRIC,
        LIBRARY_TYPE.STITCH,
        LIBRARY_TYPE.TRIM
      ].includes(this.processedItem.libraryType)
    },

    /**
     * @returns {boolean}
     */
    isSingle3DFileItem () {
      return [
        LIBRARY_TYPE.FABRIC,
        LIBRARY_TYPE.STITCH,
        LIBRARY_TYPE.TRIM
      ].includes(this.processedItem.libraryType)
    },

    /**
     * @returns {boolean}
     */
    isWorkspaceLibraryItem () {
      return [LIBRARY_TYPE.JOB, LIBRARY_TYPE.STYLE].includes(
        this.processedItem.libraryType
      )
    }
  },

  methods: {
    ...mapActions(['updateAssetStatus']),

    /**
     * @param {string} status
     */
    async updateStatus (status) {
      if (
        [ASSET_STATUS.RESUBMIT.value, ASSET_STATUS.CANCELLED.value].includes(
          status
        )
      ) {
        this.openAdvancedTransitionAction(status)
      } else {
        this.isLoadingStatus = true
        await this.updateAssetStatus({
          id: this.processedItem.id,
          assetStatus: status
        })
        this.isLoadingStatus = false
      }
    },

    /**
     * @param {object} status
     */
    openAdvancedTransitionAction (status) {
      this.styleStatusActionDefaultFormData = {
        assetStatus: ASSET_STATUS[status.toUpperCase()].label
      }

      this.styleStatusActionReasons = { changeReasons: [] }

      if (status === ASSET_STATUS.CANCELLED.value) {
        this.styleStatusActionReasons.changeReasons = REASONS_FOR_CANCELLATION
      } else if (status === ASSET_STATUS.RESUBMIT.value) {
        this.styleStatusActionReasons.changeReasons = REASONS_FOR_RESUBMITTING
      }

      this.showStyleStatusActionForm = true
    },

    /**
     *
     */
    onStyleStatusActionFormClose () {
      this.showStyleStatusActionForm = false
    },

    /**
     * @param {number} success
     */
    completeStyleStatusActionForm (success) {
      if (success) {
        this.onStyleStatusActionFormClose()
      }
    },

    /**
     * @param   {object}  processedItem
     * @param   {number}  [count=0]
     * @param   {number}  [limit]
     *
     * @returns {boolean}
     */
    hasModel3D (processedItem, count = 0, limit) {
      return (
        processedItem.filesDict &&
        processedItem.filesDict.model3D &&
        processedItem.filesDict.model3D.length >= count &&
        (!limit || processedItem.filesDict.model3D.length < limit)
      )
    },

    /**
     * @param   {string} status
     * @returns {string}
     */
    mapStatusToCSSClass (status) {
      switch (status) {
        case ASSET_STATUS.CREATED.label:
          return 'library-card-item__span-created'
        case ASSET_STATUS.RESUBMIT.label:
          return 'library-card-item__span-resubmit'
        case ASSET_STATUS.CANCELLED.label:
          return 'library-card-item__span-cancelled'
        case ASSET_STATUS.APPROVED.label:
          return 'library-card-item__span-approved'
        case ASSET_STATUS.REVIEW.label:
          return 'library-card-item__span-review'
        case ASSET_STATUS.APPROVED_FOR_DAM.label:
          return 'library-card-item__span-approved-for-dam'
        case ASSET_STATUS.REQUESTED.label:
          return 'library-card-item__span-requested'
        default:
          return ''
      }
    },

    /**
     * @param {object} processedItem
     */
    showDetailPage (processedItem) {
      const newQueryUrl = JSON.parse(JSON.stringify(this.$route.query))
      newQueryUrl.detail = processedItem.id

      this.$router.replace(
        {
          query: newQueryUrl
        },
        () => {}
      )
    },

    /**
     * Open/close dropdown on right click
     */
    handleCardContextMenu () {
      this.showDropdown = !this.showDropdown
      this.shouldDropdownPersist = true
    },

    /**
     * We need to persist the dropdown open if it was triggered by a right click
     */
    async handleVisibilityChange () {
      this.showDropdown = false

      if (this.shouldDropdownPersist) {
        await this.$nextTick()
        this.showDropdown = true
        this.shouldDropdownPersist = false
      }
    },

    /**
     * @param {boolean} isHovered
     */
    handleCardHover (isHovered) {
      if (!isHovered && this.shouldDownloadButtonPersist) {
        return
      }

      this.isHovered = isHovered
    },

    /**
     * @param {boolean} isVisible
     */
    handleFileDropdownVisibleChange (isVisible) {
      if (!isVisible) {
        this.isHovered = false
      }

      this.shouldDownloadButtonPersist = isVisible
    },

    /**
     */
    handleCardClick () {
      this.showDetailPage(this.processedItem)
    },

    // TODO remove this logic once we get information from the backend
    // about the thumbnail creation
    /**
     * @param   {object}  processedItem
     * @returns {boolean}
     */
    preparingThumbnailTimedOut (processedItem) {
      const lastUpdatedAt = new Date(processedItem.updatedAt).getTime()
      const now = Date.now()

      return now - lastUpdatedAt < PREPARE_THUMBNAIL_TIMEOUT
    },

    /**
     * This filetype doesn't generate any render images
     *
     * @param   {object}  processedItem
     * @returns {boolean}
     */
    shouldShowPreparingThumbnail (processedItem) {
      if (
        processedItem.libraryType !== LIBRARY_TYPE.STYLE &&
        !(processedItem.filesDict && this.hasModel3D(processedItem))
      ) {
        return false
      }

      const hasOnlyZprjVersions = processedItem.filesDict.model3D.every(
        model3d => {
          return (
            model3d.model_url &&
            Utils.getExtensionFromFileName(model3d.model_url) === 'zprj'
          )
        }
      )

      if (hasOnlyZprjVersions) {
        return false
      }

      return this.preparingThumbnailTimedOut(processedItem)
    },

    /**
     * @param   {object}   processedItem
     *
     * @returns {object[]}
     */
    getDropdownItems (processedItem) {
      return [
        {
          name: 'Download All Images',
          show: processedItem.libraryType === LIBRARY_TYPE.JOB,
          method: () => this.startDownload(processedItem)
        },
        {
          name: 'View Images',
          show: processedItem.libraryType === LIBRARY_TYPE.JOB,
          method: () => this.showDetailPage(processedItem)
        },
        {
          name: 'Delete',
          divided: true,
          styles: [STYLE_TYPE.DANGER],
          show: processedItem.libraryType === LIBRARY_TYPE.JOB,
          method: () => this.openConfirmDelete(processedItem)
        },
        {
          name: `Open ${processedItem.libraryType}`,
          show: this.isSingle3DFileItem,
          method: () => this.showDetailPage(this.processedItem)
        },
        {
          name: `Download ${processedItem.libraryType}`,
          show: this.isSingle3DFileItem && this.hasModel3D(processedItem),
          dataTestid: 'downloadTrigger',
          children: this.getDownloadChildren(processedItem),
          method: () =>
            this.hasModel3D(processedItem, 1, 2) &&
            this.downloadFile({
              item: processedItem,
              fileIndex: 0
            })
        }
      ]
    },

    /**
     * @param {object} processedItem
     */
    openConfirmDelete (processedItem) {
      const { name, status, libraryType } = processedItem

      let messageAction = 'delete'

      if (libraryType === LIBRARY_TYPE.JOB && status !== JOB_STATUS.SUCCESS) {
        messageAction = 'cancel and delete'
      }

      const message = `This will permanently ${messageAction} ${name}. Continue?`

      this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        showClose: false
      })
        .then(() => {
          this.confirmDelete(processedItem)
        })
        .catch(() => {})
    },

    /**
     * @param {object} processedItem
     */
    confirmDelete (processedItem) {
      const { id, name, libraryType } = processedItem
      this.$emit('delete-item', {
        id,
        name,
        libraryType
      })
    },

    /**
     * @param   {object} item -- proccessed Item
     * @returns {*}           -- callback could return anything
     */
    startDownload (item) {
      this.showDownloadSpinner(item.id)

      let object = TRACKER_OBJECTS.LIBRARY_3DFILE
      let label = '3D File'

      if (item.libraryType === LIBRARY_TYPE.JOB) {
        object = TRACKER_OBJECTS.RENDER
        label = 'Render images'
      }

      this.$tracking.trackEvent({
        object,
        action: TRACKER_EVENTS.DOWNLOADED,
        label,
        value: item.id,
        item
      })

      if (item.downloadCallback) {
        return item.downloadCallback(item)
      }
    },

    /**
     * @param {object} item
     * @param {number} index
     * @param {string} [action=TRACKER_EVENTS.DOWNLOADED]
     */
    trackItemDownload (item, index, action = TRACKER_EVENTS.DOWNLOADED) {
      const downloadedFile = item.filesDict.model3D[index]

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.LIBRARY_3DFILE,
        action,
        label: item.name,
        value: downloadedFile,
        item
      })
    },

    /**
     * @param {number} id
     */
    showDownloadSpinner (id) {
      this.showDownloadSpinnerStatus = id
      setTimeout(() => {
        this.showDownloadSpinnerStatus = null
      }, 1000)
    },

    /**
     * @param {object} item
     * @param {number} index
     */
    sendItemToPlugin (item, index) {
      this.sendDataToBrowzwear(item, index)
      this.trackItemDownload(item, index, TRACKER_EVENTS.SENT_TO_PLUGIN)
    },

    /**
     * @param   {object} payload
     * @param   {object} payload.item
     * @param   {number} [payload.fileIndex=0]
     *
     * @returns {string}                       url - File to be downloaded
     */
    downloadFile ({ item, fileIndex = 0 }) {
      if (this.browzwearAPI) {
        this.sendItemToPlugin(item, fileIndex)
      } else {
        const downloader = document.createElement('a')

        if (item?.main_model) {
          downloader.setAttribute('href', item.main_model)
        } else {
          downloader.setAttribute(
            'href',
            item.filesDict.model3D[fileIndex].assets_url
          )
        }

        downloader.setAttribute('download', '')
        downloader.click()
      }

      this.trackItemDownload(item, fileIndex)

      return item.assets_url
    },

    /**
     * @param   {object}   processedItem
     *
     * @returns {object[]}
     */
    getDownloadChildren (processedItem) {
      if (
        processedItem.libraryType === LIBRARY_TYPE.TRIM &&
        this.hasModel3D(processedItem, 2)
      ) {
        return processedItem.filesDict.model3D.map((file, index) => ({
          ...file,
          dataTestid: `downloadItem${index}`,
          name: file.description,
          method: () =>
            this.downloadFile({
              item: processedItem,
              fileIndex: index
            })
        }))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$card-info-subtitle-height: 18px;
$multiple-image-min-size: 50%;
$single-image-container-size: 80%;
$sustainability-rating-font-size: 24px;
$card-status-overlay-opacity: 0.4;
$span-status-padding: 12px;
$span-status-space-top: -1px;
$unread-notif-size: 18px;
$quality-check-icon-size: 18px;

.library-card-item {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  /deep/ .el-card__body {
    width: 100%;
    height: 100%;
  }
}

.library-card-item--disabled {
  opacity: 0.6;
  pointer-events: none;
}

.library-card-item-preview {
  width: 100%;
  height: 100%;
  padding: spacing(1);
}

.library-card-item__image-group {
  position: relative;
  width: $single-image-container-size;
  margin: auto;
  padding-top: $single-image-container-size;
}

.library-card-item__status-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-layer-0;
  display: none;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-s;

  .library-card-item__status-overlay-icon {
    @include flex-center;

    width: 100%;
    height: 100%;
  }

  &.status--upload-pending,
  &.status--job-prepare-download {
    display: block;
    background-color: rgba($blue, $card-status-overlay-opacity);
  }

  &.status--upload-failed {
    display: block;
    background-color: rgba($red, $card-status-overlay-opacity);
  }

  &.status--job-started {
    display: block;
    background-color: rgba($green, $card-status-overlay-opacity);
  }

  &.status--job-created {
    display: block;
    background-color: rgba($blue-dark, $card-status-overlay-opacity);
  }
}

.library-card-item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-origin: content-box;

    &.placeholder {
      background-image: url('~@/assets/images/img-placeholder.svg');
    }

    &.placeholder--preparing {
      background-image: none;
    }
  }
}

.library-card-item__image--fabrics {
  &.image {
    background-repeat: repeat;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: inherit;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: spacing(2);
    border-image: url('~@/assets/images/fabric-border.png') 48 / 1 round;
    content: ' ';
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .library-card-item__image--fabrics::after {
      border-image: url('~@/assets/images/fabric-border.png') 17% repeat;
    }
  }
}

.preparing-thumbnail {
  @include flex-center;
  @include position-absolute;

  flex-direction: column;
  text-align: center;
}

.library-card-item__image-multiple {
  @include flex-center;

  position: absolute;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  .image {
    flex: 1 1 auto;
    min-width: $multiple-image-min-size;
    min-height: $multiple-image-min-size;
    padding: spacing(1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-origin: content-box;
  }
}

.library-card-item__info {
  position: relative;
  min-width: 0;
  margin-top: spacing(1);
  padding: 0 spacing(0.5);
}

.library-card-item__info-title {
  @include text-ellipsis;
  @include text-body-thick;

  width: 100%;
  padding-right: spacing(1);
}

.library-card-item__info-sub-title {
  @include text-ellipsis;
  @include text-label;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $card-info-subtitle-height;
  color: $grey;
}

.library-card-item__status {
  border-radius: spacing(2);
}

.library-card-item__status span {
  position: relative;
  top: $span-status-space-top;
  padding: $span-status-padding;
  color: $white;
  font-size: $font-size-status;
}

.library-card-item__span-approved {
  background-color: $status-approved;
}

.library-card-item__span-review {
  background-color: $status-review;
}

.library-card-item__span-resubmit {
  background-color: $status-resubmit;
}

.library-card-item__span-cancelled {
  background-color: $status-cancelled;
}

.library-card-item__span-created {
  background-color: $status-created;
}

.library-card-item__span-approved-for-dam {
  background-color: $status-approved-for-dam;
}

.library-card-item__span-requested {
  background-color: $status-requested;
}

.library-card-item__actions {
  position: absolute;
  right: spacing(1);
  bottom: spacing(1);
  align-items: center;

  a {
    margin-right: spacing(1);
    color: $blue;
    cursor: pointer;

    &:hover {
      color: $blue-light;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.library-card-item.is-always-shadow {
  box-shadow: $shadow-default;
}

.library-card-item__actions-menu {
  color: $blue;

  &:hover {
    color: $blue-light;
  }
}

.library-card-item__actions-delete {
  color: $red;

  &:hover {
    color: $red-light;
  }
}

.library-card-item__actions-delete--icon {
  font-weight: $font-weight-bold;
}

.library-card-item__rate {
  height: inherit;
  line-height: 2;
}

.library-card-item__rate-icon {
  display: inline-block;
  width: spacing(1);
  height: inherit;
  margin-right: spacing(1/2);

  &::before {
    height: inherit;
    color: $green;
    font-size: $sustainability-rating-font-size;
    line-height: 0;
    content: '\2022';
  }
}

.library-card-item__weight {
  @include text-label;

  height: $card-info-subtitle-height;
  color: $grey;
}

.library-card-item__unread-notifications-icon {
  //position: absolute;
  //right: spacing(5/8);
  bottom: 0;

  i {
    color: $red;
    font-size: $unread-notif-size;
  }
}

.library-card-item__quality-check-icon {
  //position: absolute;
  //right: spacing(3);

  i {
    color: $status-approved;
    font-size: $quality-check-icon-size;
  }
}
</style>
