<template>
  <div
    :class="[
      'style-comments-event',
      { 'style-comments-event--faded': isFaded }
    ]"
  >
    <div
      v-if="item.type === STYLE_EVENT_TYPE.COMMENT"
      :class="[
        'style-comments-event__content',
        'style-comments-event__content--comment',
        item.comment.role === ROLE_TYPE.CREATOR.displayName
          ? 'style-comments-event__content--comment-creator'
          : 'style-comments-event__content--comment-viewer'
      ]"
    >
      <section class="style-comments-event__content-section">
        <div class="style-comments-event__text">
          <div v-html="highlightMentions(item.comment.text)" />
        </div>
        <div
          v-if="item.comment.attachment"
          class="style-comments-event__metadata"
        >
          Attachment: {{ item.comment.attachment.name }}
          <a
            href="#"
            @click="downloadAttachment(item)"
          >
            <div
              class="download-square"
              :style="downloadSquareStyle(item.comment.attachment.type)"
            >
              {{ downloadSquareText(item.comment.attachment.type) }}
            </div>
          </a>
        </div>
        <div class="style-comments-event__metadata">
          <strong>
            {{ item.comment.contributor.email }}
            ({{ item.comment.role }})
          </strong>
          {{ formatDateShort(item.updated_at) }}
        </div>
      </section>

      <StitchDropdown
        v-if="showControls"
        ref="optionMenuDropdown"
        :icon-type="ICON_TYPE.MENU"
        :append-to-body="false"
        :dropdown-items="dropdownItems"
      />
    </div>

    <div
      v-else-if="item.type === STYLE_EVENT_TYPE.MAIN_VERSION_SET"
      class="style-comments-event__content style-comments-event__content--info"
    >
      <b>{{ getVersionName(item.style_version_id) }}</b> is now the Main Version
    </div>

    <div
      v-else-if="item.type === STYLE_EVENT_TYPE.NEW_VERSION_ADDED"
      class="style-comments-event__content style-comments-event__content--info"
    >
      <b>New Version</b> posted 😎
    </div>
    <div
      v-else-if="item.type === STYLE_EVENT_TYPE.STATUS_CHANGED"
      class="style-comments-event__content style-comments-event__content--info"
    >
      <b>Status changed from {{ item.asset_status_data.previous_status }} to
        {{ item.asset_status_data.new_status }} by
        {{ item.asset_status_data.changed_by }}</b>
    </div>
    <div
      v-else
      class="style-comments-event__content style-comments-event__content--info"
    >
      Unsupported event type {{ item.type }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  ICON_TYPE,
  STYLE_TYPE,
  DROPDOWN_PLACEMENT
} from '@/constants/dropdownOptions'
import VueTypes from 'vue-types'
import { STYLE_EVENT_TYPE } from '@/constants/styleEventType'
import { StyleEventShape } from '@/types'
import { ROLE_TYPE } from '@/constants/roleType'
import { formatToLocaleDateShort } from '@/services/utils'
import { DataUtils } from '@/mixins/utils.js'

export default {
  name: 'StyleCommentsEvent',

  mixins: [DataUtils],

  props: {
    item: StyleEventShape.loose.isRequired,
    showControls: VueTypes.bool.def(true),
    isFaded: VueTypes.bool.def(false)
  },

  data () {
    return {
      ICON_TYPE,
      STYLE_EVENT_TYPE,
      DROPDOWN_PLACEMENT,
      ROLE_TYPE
    }
  },

  computed: {
    ...mapGetters(['getVersionById']),

    /**
     * @returns {object[]}
     */
    dropdownItems () {
      const { id: commentId, text } = this.item.comment

      return [
        {
          name: 'Edit Comment',
          method: () => {
            this.$emit('update-comment', { commentId, text })
          }
        },
        {
          name: 'Delete Comment',
          styles: [STYLE_TYPE.DANGER],
          method: () => {
            this.$emit('delete-comment', { commentId })
          }
        }
      ]
    }
  },

  methods: {
    /**
     * @param {object} item
     */
    downloadAttachment (item) {
      this.triggerDownload(item.comment.attachment.file_url)
    },

    /**
     * Returns the background color based on file extension
     *
     * @param   {string} fileType
     *
     * @returns {string}
     */
    getBgColor (fileType) {
      const ext = fileType.toLowerCase()

      if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'].includes(ext)) {
        return 'orange'
      } else if (['pdf', 'doc', 'docx', 'txt', 'ppt', 'pptx'].includes(ext)) {
        return 'blue'
      } else if (['xls', 'xlsx', 'csv'].includes(ext)) {
        return 'green'
      } else if (['zip', 'rar', 'tar', 'gz'].includes(ext)) {
        return 'gray'
      }

      return 'black'
    },

    /**
     * Returns the file extension text in uppercase
     *
     * @param   {string} fileType
     *
     * @returns {string}
     */
    downloadSquareText (fileType) {
      return fileType.toUpperCase()
    },

    /**
     * Returns the style object for the download square
     *
     * @param   {object} item
     *
     * @returns {string}
     */
    downloadSquareStyle (item) {
      return {
        backgroundColor: this.getBgColor(item)
      }
    },

    /**
     * @param   {Date}   date
     *
     * @returns {string}
     */
    formatDateShort (date) {
      return formatToLocaleDateShort(date)
    },

    /**
     * @param   {number | null} versionId
     *
     * @returns {string}
     */
    getVersionName (versionId) {
      let version

      if (versionId) {
        version = this.getVersionById(versionId)
      }

      return version ? version.name : 'A deleted version'
    },

    /**
     * @returns {Function}
     */
    findMatchFunction () {
      return match => `<b>${match}</b>`
    },

    /**
     * @param   {string} comment
     *
     * @returns {string}
     */
    highlightMentions (comment) {
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g

      return comment.replace(emailRegex, this.findMatchFunction())
    }
  }
}
</script>

<style lang="scss" scoped>
$event-faded-opacity: 0.5;
$square-size: 40px;
$square-margin-right: 8px;
$square-font-weight: bold;

.download-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $square-size;
  height: $square-size;
  margin-right: $square-margin-right;
  color: $white;
  font-weight: $square-font-weight;
}

.style-comments-event {
  width: 100%;
  margin-bottom: spacing(1);
}

.style-comments-event--faded {
  opacity: $event-faded-opacity;
}

.style-comments-event__content {
  margin: auto;
  border-radius: $border-radius-m;
}

.style-comments-event__content--info {
  @include text-label;

  width: spacing(50);
  padding: spacing(1);
  color: $grey;
  font-weight: $font-weight-semibold;
  text-align: center;
  background-color: $grey-ultra-light;
}

.style-comments-event__content--comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: spacing(2);
}

.style-comments-event__content--comment-creator {
  background-color: $blue-light;
}

.style-comments-event__content--comment-viewer {
  background-color: $yellow-light;
}

.style-comments-event__content-section {
  margin-right: spacing(1);
}

.style-comments-event__text {
  margin-bottom: spacing(1/2);
  color: $grey-dark;
  white-space: pre-wrap;
}

.style-comments-event__metadata {
  @include text-label;
}
</style>
