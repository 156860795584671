<template>
  <div class="library-status-selector">
    <ElementSelect
      v-model="selectedStatus"
      placeholder="---"
      size="small"
      :class="[libraryStatusSelector, mapStatusToCSSClass]"
      @change="updateSelectedStatus"
    >
      <ElementTooltip
        v-for="status in transitions"
        :key="status.value"
        ref="tooltip"
        effect="dark"
        :content="`You cannot change status from ${selectedStatus} to ${status.label}.`"
        placement="right-start"
        :disabled="status.is_possible || status.value === selectedStatus"
        :open-delay="400"
        :enterable="false"
      >
        <ElementOption
          :label="status.action"
          :value="status.value"
          :disabled="!status.is_possible && status.value !== selectedStatus"
        />
      </ElementTooltip>
    </ElementSelect>
    <template v-if="size !== 'small' && item.assetStatusChangeLog">
      <ElementTooltip
        effect="dark"
        :content="`Changed by ${item.assetStatusChangeLog.changed_by},
          ${formatToLocaleDateLong(item.assetStatusChangeLog.changed_at)}`"
        placement="right-start"
        :open-delay="400"
        :enterable="false"
      >
        <i
          id="library-status--log-tooltip"
          class="el-icon-info"
        />
      </ElementTooltip>
      <td>
        {{ item.assetStatusChangeLog.change_reason }}
      </td>
    </template>
  </div>
</template>

<script>
import { ASSET_STATUS } from '@/constants/assetStatuses'
import {
  formatToLocaleDateLong,
  formatToLocaleDateShort
} from '@/services/utils'

export default {
  name: 'LibraryStatusSelector',

  props: {
    item: Object,
    transitions: Array,
    reset: {
      type: Boolean,
      default: false,
      required: false
    },
    size: {
      type: String,
      default: 'medium',
      required: false
    }
  },

  emits: ['update-status'],

  data () {
    return {
      selectedStatus: null
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    mapStatusToCSSClass () {
      switch (this.selectedStatus) {
        case ASSET_STATUS.CREATED.label:
          return 'library-status__input-created'
        case ASSET_STATUS.RESUBMIT.label:
        case ASSET_STATUS.RESUBMIT.value:
          return 'library-status__input-resubmit'
        case ASSET_STATUS.CANCELLED.label:
        case ASSET_STATUS.CANCELLED.value:
          return 'library-status__input-cancelled'
        case ASSET_STATUS.APPROVED.label:
          return 'library-status__input-approved'
        case ASSET_STATUS.REVIEW.label:
          return 'library-status__input-review'
        case ASSET_STATUS.APPROVED_FOR_DAM.label:
        case ASSET_STATUS.APPROVED_FOR_DAM.value:
          return 'library-status__input-approved-for-dam'
        case ASSET_STATUS.REQUESTED.label:
          return 'library-status__input-requested'
        default:
          return ''
      }
    },

    /**
     * @returns {string}
     */
    libraryStatusSelector () {
      return this.size === 'small'
        ? 'library-status__selector-small'
        : 'library-status__selector'
    }
  },

  watch: {
    /**
     *
     */
    item () {
      this.selectedStatus = this.item.assetStatus
    },

    /**
     *
     */
    reset () {
      this.selectedStatus = this.item.assetStatus
    }
  },

  created () {
    if (this.item !== null) {
      this.selectedStatus = this.item.assetStatus
    }
  },

  methods: {
    formatToLocaleDateLong,

    formatToLocaleDateShort,

    /**
     */
    updateSelectedStatus () {
      this.$emit('update-status', this.selectedStatus)
    }
  }
}
</script>

<style lang="scss" scoped>
$library-status-selector-width: 20%;

.library-status__selector {
  width: spacing(22);
  color: $white;
}

.library-status__selector-small {
  width: spacing(12.5);
  color: $white;
}
</style>

<style lang="scss">
.library-status__selector input {
  color: $white;
  font-weight: $font-weight-bold;
  border-radius: spacing(2.5);
}

#library-status--log-tooltip {
  margin-left: spacing(1);
  cursor: pointer;
}

.library-status__selector-small .el-input__inner,
.library-status__selector-small .el-input__inner:focus {
  height: spacing(2.25) !important;
  border-color: transparent !important;
}

.library-status__selector .el-input__inner:focus {
  border-color: transparent !important;
}

.library-status__selector-small input {
  color: $white;
  font-size: spacing(1.25);
  border-radius: spacing(2.5);
}

.library-status__selector-small .el-select__caret {
  font-size: spacing(1.25) !important;
}

.library-status__selector-small .el-input__suffix {
  top: spacing(1.25) !important;
  right: spacing(1.25) !important;
}

.library-status__input-approved input {
  background-color: $status-approved;
}

.library-status__input-review input {
  background-color: $status-review;
}

.library-status__input-resubmit input {
  background-color: $status-resubmit;
}

.library-status__input-cancelled input {
  background-color: $status-cancelled;
}

.library-status__input-created input {
  background-color: $status-created;
}

.library-status__input-approved-for-dam input {
  background-color: $status-approved-for-dam;
}

.library-status__input-requested input {
  background-color: $status-requested;
}

.el-select .el-input .el-select__caret {
  color: $white;
  font-weight: $font-weight-bold;
}
</style>
