export const FEATURE = Object.freeze({
  STITCH_PLUGIN: 'fe_stitch_plugin',
  TRIM_TAB_PLUGIN: 'fe_trim_tab_plugin',
  ENDPOINT_MANAGER_PLUGIN: 'fe_endpoint_manager_plugin',
  STYLE_COMMENTS: 'fe_style_comments',
  NOTIFICATIONS_SETTINGS: 'fe_notifications_settings',
  WORKSPACES: 'workspaces',
  RENDER_ENGINE_CHOICE: 'render_engine_choice',
  VRAY_ONLY_TEAM: 'vray_only_team',
  STYLE_BULK_CREATION: 'style_bulk_creation',
  IS_ADVANCED_USER: 'is_advanced_user'
})
