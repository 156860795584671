import { HTTP } from '@/services/axios'

/**
 * @param   {object}          payload
 * @param   {File}            payload.file
 * @param   {string}          payload.name
 * @param   {boolean}         payload.isMain
 * @param   {string}          payload.renderEngine
 * @param   {number}          payload.styleId
 *
 * @returns {Promise<object>}
 */
const addStyleVersion = async ({
  file,
  name,
  isMain,
  renderEngine,
  styleId
}) => {
  const fileForm = new FormData()

  if (file) {
    fileForm.append('model', file)
  }

  // gives empty string because formData converts null into 'null'
  fileForm.append('name', name || '')
  fileForm.append('is_main', isMain)
  fileForm.append('render_engine', renderEngine)

  const response = await HTTP.post(`/styles/${styleId}/versions/`, fileForm)

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 * @param   {string}          payload.name
 * @param   {string}          payload.code
 * @param   {number}          payload.mainColorId
 * @param   {string}          payload.plmStatus
 *
 * @returns {Promise<object>}
 */
const addOption = async ({
  styleId,
  versionId,
  name,
  code,
  mainColorId,
  plmStatus
}) => {
  const response = await HTTP.post(
    `styles/${styleId}/versions/${versionId}/options`,
    {
      name,
      code,
      main_color_id: mainColorId,
      plm_status: plmStatus
    }
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 * @param   {number}          payload.optionId
 * @param   {string}          payload.name
 * @param   {string}          payload.code
 * @param   {number}          payload.mainColorId
 * @param   {string}          payload.plmStatus
 * @param   {number}          payload.index
 *
 * @returns {Promise<object>}
 */
const updateOption = async ({
  styleId,
  versionId,
  optionId,
  name,
  code,
  mainColorId,
  plmStatus,
  index
}) => {
  const response = await HTTP.patch(
    `styles/${styleId}/versions/${versionId}/options/${optionId}`,
    {
      name,
      code,
      main_color_id: mainColorId,
      plm_status: plmStatus,
      index
    }
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 * @param   {number}          payload.optionId
 *
 * @returns {Promise<object>}
 */
const deleteOption = async ({ styleId, versionId, optionId }) => {
  return await HTTP.delete(
    `styles/${styleId}/versions/${versionId}/options/${optionId}`
  )
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const addStyleOptionImage = async payload => {
  const { styleId, versionId, optionId, url, file } = payload
  const fileForm = new FormData()

  if (file) {
    fileForm.append('file', file)
  }

  if (url) {
    fileForm.append('url', url)
  }

  const response = await HTTP.post(
    `styles/${styleId}/versions/${versionId}/options/${optionId}/images/`,
    fileForm
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 * @param   {number}          payload.optionId
 * @param   {number}          payload.imageId
 * @param   {File}            payload.file
 * @param   {boolean}         payload.isThumbnail
 *
 * @returns {Promise<object>}
 */
const updateStyleOptionImage = async ({
  styleId,
  versionId,
  optionId,
  imageId,
  file,
  isThumbnail
}) => {
  const fileForm = new FormData()

  if (file) {
    fileForm.append('file', file)
  }

  fileForm.append('is_thumbnail', isThumbnail || false)

  const response = await HTTP.put(
    `styles/${styleId}/versions/${versionId}/options/${optionId}/images/${imageId}`,
    fileForm
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 * @param   {number}          payload.optionId
 * @param   {number}          payload.imageId
 *
 * @returns {Promise<object>}
 */
const deleteStyleOptionImage = async ({
  styleId,
  versionId,
  optionId,
  imageId
}) => {
  const response = await HTTP.delete(
    `styles/${styleId}/versions/${versionId}/options/${optionId}/images/${imageId}`
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {string}          payload.plmCode
 * @param   {number}          payload.seasonId
 *
 * @returns {Promise<object>}
 */
const refreshPLMCode = async ({ styleId, plmCode, seasonId }) => {
  const response = await HTTP.patch(`styles/${styleId}`, {
    plm_code: plmCode,
    season_id: seasonId
  })

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const updateStyleVersion = async payload => {
  const fileForm = new FormData()

  if (payload.name) {
    // gives empty string because formData converts null into 'null'
    fileForm.append('name', payload.name || '')
  }

  if (payload.file) {
    fileForm.append('model', payload.file)
  }

  if (payload.renderEngine) {
    fileForm.append('render_engine', payload.renderEngine)
  }

  if (payload.isMain === true || payload.isMain === false) {
    fileForm.append('is_main', payload.isMain)
  }

  const response = await HTTP.patch(
    `styles/${payload.styleId}/versions/${payload.versionId}`,
    fileForm
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 *
 * @returns {Promise<object>}
 */
const deleteStyleVersion = async ({ styleId, versionId }) => {
  const response = await HTTP.delete(`styles/${styleId}/versions/${versionId}`)

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.versionId
 *
 * @returns {Promise<object>}
 */
const rerenderStyleVersion = async ({ styleId, versionId }) => {
  const formData = new FormData()
  formData.append('rerender', true)

  const response = await HTTP.patch(
    `styles/${styleId}/versions/${versionId}`,
    formData
  )

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const fetchPlmOptions = async payload => {
  const { styleId, versionId, onlyUnassigned = false } = payload
  const response = await HTTP.get(
    `styles/${styleId}/versions/${versionId}/plm_options`,
    {
      params: {
        only_unassigned: onlyUnassigned
      }
    }
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {string}          payload.filterBy
 *
 * @returns {Promise<object>}
 */
const fetchStyleEvents = async ({ styleId, filterBy }) => {
  const response = await HTTP.get(`styles/${styleId}/events`, {
    params: {
      filter_by: filterBy
    }
  })

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {string}          payload.text
 * @param   {File}            [payload.attachmentFile]
 *
 * @returns {Promise<object>}
 */
const addStyleComment = async ({ styleId, text, attachmentFile }) => {
  let data

  if (attachmentFile) {
    // If there's a file, use FormData so Django sees it as an uploaded file
    // POST as multipart/form-data
    data = new FormData()
    data.append('text', text)
    data.append('attachment_file', attachmentFile)
  } else {
    // POST as JSON
    data = { text }
  }

  const response = await HTTP.post(`styles/${styleId}/comments`, data)

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.commentId
 * @param   {string}          payload.text
 *
 * @returns {Promise<object>}
 */
const updateStyleComment = async ({ styleId, commentId, text }) => {
  const response = await HTTP.patch(`styles/${styleId}/comments/${commentId}`, {
    text
  })

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 * @param   {number}          payload.commentId
 *
 * @returns {Promise<object>}
 */
const deleteStyleComment = async ({ styleId, commentId }) => {
  return await HTTP.delete(`styles/${styleId}/comments/${commentId}`)
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.styleId
 *
 * @returns {Promise<object>}
 */
const resetStyleUnreadNotificationsCount = async ({ styleId }) => {
  return await HTTP.post(`styles/${styleId}/read`)
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.groupId
 * @param   {number}          payload.seasonId
 * @param   {boolean}         payload.assignedOnly
 *
 * @returns {Promise<object>}
 */
const fetchTags = async ({ groupId, seasonId, assignedOnly = false }) => {
  const response = await HTTP.get('/tags/', {
    // For choosing 'all', the BE accepts the 'group' query parameter instead of 'group_id'
    params: {
      ...(groupId === 'all' ? { group: 'all' } : { group_id: groupId }),
      season_id: seasonId,
      assigned_only: assignedOnly
    }
  })

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const createItemTag = async payload => {
  const { styleId, versionId, optionId, text } = payload

  let url = `/styles/${styleId}/tags/`

  if (optionId) {
    url = `styles/${styleId}/versions/${versionId}/options/${optionId}/tags/`
  }

  const response = await HTTP.post(url, {
    text
  })

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const deleteItemTag = async payload => {
  const { styleId, versionId, optionId, tagId } = payload

  let url = `/styles/${styleId}/tags/${tagId}/`

  if (optionId) {
    url = `styles/${styleId}/versions/${versionId}/options/${optionId}/tags/${tagId}`
  }

  return await HTTP.delete(url)
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const updateQualityCheckStatus = async payload => {
  const { styleId, passed } = payload

  const response = await HTTP.post(`styles/${styleId}/qc-status/`, {
    passed: passed
  })

  return response.data
}

const styleAPI = {
  addStyleVersion,
  addStyleOptionImage,
  addOption,
  updateOption,
  deleteOption,
  updateStyleOptionImage,
  deleteStyleOptionImage,
  updateStyleVersion,
  deleteStyleVersion,
  rerenderStyleVersion,
  refreshPLMCode,
  fetchPlmOptions,
  fetchStyleEvents,
  addStyleComment,
  updateStyleComment,
  deleteStyleComment,
  resetStyleUnreadNotificationsCount,
  createItemTag,
  deleteItemTag,
  fetchTags,
  updateQualityCheckStatus
}

export default styleAPI
